<template>
  <div class="">
    <div class="h-20 container mx-auto px-4 block blocktoflex">
      <img src="../../assets/images/Logotipo_Bluexpress.svg" alt="" />
    </div>

    <div class="container mx-auto px-4 flex justify-between flextoblock">
      <div class="inline-flex inlinetoblock">
        <div class="mr-16">
          <p class="title-footer">DIRECCIÓN</p>
          <p class="text-footer">CARACAS, VENEZUELA.</p>
          <!-- <p class="text-footer">
      ZONA INDUSTRIAL LA NAYA, CARRETERA VIEJA MINAS <br>
      DE BARUTA. SECTOR SANTA INÉS, CALLE TIUNA, <br>
      EDIFICIO TIUNA. PLANTA ALTA. ZONA POSTAL 1080. <br>
      CARACAS, VENEZUELA.
      </p> -->
        </div>
        <div class="mr-16">
          <p class="title-footer">TELEFONOS</p>
          <p class="text-footer">+58 424 130 07 34 <br /></p>
          <!-- <p class="text-footer">
      +58 212 000 00 00 <br>
      +58 212 000 00 00 <br>
      +58 212 000 00 00
      </p> -->
        </div>
        <div class="grid">
          <p class="title-footer">MENÚ</p>
          <span class="text-footer">CATALOGOS</span>
          <span class="text-footer">TIENDA</span>
          <span class="text-footer">NOSOTROS</span>
          <span class="text-footer">CONTÁCTANOS</span>
          <span class="text-footer">ENCÚENTRANOS</span>
        </div>
      </div>
      <div class="copyright flex items-center">
        <p>2020 Copyright © Bluexpress C.A. Todos los derechos reservados.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@media only screen and (max-width: 639px) {
  .inlinetoblock {
    display: block;
    margin: 30px 0px;
  }
  .mr-16 {
    margin: 20px 0px;
  }
  .blocktoflex {
    display: flex;
    justify-content: center;
    padding: 0 !important;
    align-items: center;
  }
  .blocktoflex img {
    height: 2.5rem;
  }
  .copyright{
    justify-content: center;
  }
}
</style>
