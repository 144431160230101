<template>
  <div
    class="container sm:container-none md:container mx-auto px-0 py-8 block sm:flex items-center justify-between text-white sm:text-xs lg:text-base"
  >
    <div class="flex items-center justify-between sm:w-1/4 cursor-pointer" @click="navHome">
      <img
        src="../../assets/images/Logotipo_Bluexpress.svg"
        alt=""
        class="h-1/4 mr-12"
      />
      <div class="block menu-icon sm:hidden">
        <button
          id="nav-toggle"
          class="flex items-center px-3 py-2"
          @click="toggleMenu"
        >
          <img
            src="../../assets/images/barras.svg"
            class="w-6"
            alt="Icono Menu"
          />
        </button>
      </div>
    </div>

    <div
      class="sm:flex flex-col sm:flex-row items-center justify-between sm:py-8 sm:mb-0 sm:mt-1.5 sm:w-3/4"
      :class="{ hidden: !showMenu, 'flex py-14 -mb-52': showMenu }"
    >
      <!-- :class="{ hidden: !showMenu, 'flex py-14 -mb-64': showMenu }" -->
      <div
        class="flex flex-col sm:flex-row justify-between sm:w-3/5 xl:w-2/5 nav-content items-center"
      >
        <router-link to="/" class="button-main">CATALOGOS</router-link>
        <router-link to="/distribution" class="button-main"
          >DISTRIBUIDORES</router-link
        >
        <router-link to="/about" class="button-main">NOSOTROS</router-link>
      </div>
      <!-- <div class="md:pl-3 nav-content flex flex-col sm:flex-row items-center">
        <button
          class="sm:rounded-full sm:py-3 sm:px-4  md:py-3 md:px-5 sm:bg-white sm:bg-opacity-25 sm:box-border sm:mx-2 md:mx-4 button-main"
        ><router-link to="/auth">
          INICIA SESION
        </router-link>
        </button>
        <button
          class="sm:rounded-full  sm:py-3 sm:px-4  md:py-3 md:px-5 sm:bg-white sm:box-border sm:mx-2 md:mx-4 button-main sm:text-blue-600"
        >
          REGISTRATE
        </button>
      </div> -->
      <div class="nav-content">
        <span class="font-bold">EN</span>
        <span>|</span>
        <span>US</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    navHome() {
      if(this.$route.name != "Home")
        this.$router.push({ name: "Home"});
    },
  },
};
</script>

<style></style>
