<template>
  <div class="flex flextoblock " v-if="sections">
    <div
      class="duo1 bg-cover container mx-auto px-12 py-32 lg:px-20 lg:py-36 xl:px-24 justify-center content-center "
      :style="`background-image: url(${duo1.background_image})`"
    >
      <div class="flex grid items-center ">
        <p class="mb-2 blocktoflex">
          <!-- <span class="after-title">{{duo1.title}} &nbsp;</span> -->
          <span class="title-section" style="padding-left: 5px"
            >&nbsp; {{ duo1.title }}
          </span>
        </p>
        <p class="subtitle-section mb-4">
          {{ duo1.subtitle }}
        </p>
        <button
          class="w-48 button-main rounded-full py-2 px-6 bg-white box-border button-text-black"
          @click="getPdf"
        >
          {{ duo1.button_name }} >
        </button>
      </div>
    </div>
    <RRSS
      class="duo2 container mx-auto px-12 lg:p-20 xl:p-24 blocktoflex"
      :rrss="rrss"
    ></RRSS>
  </div>
</template>

<script>
import RRSS from "../Rrs/Index.vue";
export default {
  components: {
    RRSS,
  },
  props: ["sections"],
  computed: {
    duo1() {
      return this.sections.find((section) => section.type_banner === "duo_1");
    },
    rrss() {
      return this.sections.find(
        (section) => section.type_banner === "section_rrss"
      );
    },
  },
  methods: {
    getPdf() {
      window.open(this.duo1.pdf_url, "_blank");
    },
  },
};
</script>

<style>
.duo1 {
  /* background-image: url(../../assets/images/bgduo1.svg); */
  width: 50vw;
  height: 50vh;
}
.duo2 {
  width: 50vw;
  height: 50vh;
  background-position: center;
  display: flex;
  align-items: baseline;
}

@media only screen and (max-width: 639px) {
  .flextoblock {
    display: block;
    height: auto;
  }
  .duo1 {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .duo2 {
    width: 100%;
  }
  .button-main {
    display: flex;
    justify-self: center;
  }
}
</style>
