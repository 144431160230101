<template>
  <div class="bgblue-2 bg-cover" :style="`background-image: url(${background})`">
    <div class="container mx-auto justify-center content-center h-full">
      <div class="flex grid items-center blocktoflex2 py-44">
        <p>
          <span class="after-title">CATÁLOGO</span>
          <span class="title-section mb-6"> {{ title }} </span>
        </p>
        <p class="subtitle-section mb-6">{{ subtitle }}</p>
        <button
          class="w-36 button-main rounded-full py-3 px-8 bg-white box-border button-text-white bg-opacity-25 text-center-important"
        @click="getPdf">{{ button }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["background", "title", "subtitle", "button", "pdf_url"],
  methods:{
    getPdf(){
      window.open(this.pdf_url, '_blank')
    }
  }
};
</script>

<style>
.bgblue-2 {
  /* background-image: url(../../assets/images/bgblue2.svg);
  background-image: url(../../assets/images/image-url-2.svg);
  background-image: url(../../assets/images/image-url-3.svg); */
  height: 50vh;
  width: 100%;
}
.text-center-important{
  text-align: center  !important;;
}

@media only screen and (max-width: 639px) {
  .blocktoflex2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 90px 0px;
  }
}
</style>
