<template>
  <div class="poppins" v-if="sections.length > 0">
    <div
      class="bg-cover bgblue-1"
      :style="`background-image: url(${introSection.background_image})`"
    >
      <NavBarComponent></NavBarComponent>
      <div
        class="container block mx-auto py-80 blocktoflex1"
        v-if="introSection"
      >
        <p class="mb-6 title-main">{{ introSection.title }}</p>
        <p class="mb-6 subtitle-main">
          {{ introSection.subtitle }}
        </p>
        <button
          class="box-border px-8 py-3 bg-white rounded-full button-text-blue"
          @click="getPdf"
        >
          {{ introSection.button_name }}
        </button>
      </div>
    </div>
    <div id="catalogos">
      <div v-for="element in sectionsFilter" :key="element.id">
        <SectionComponent
          :background="element.background_image"
          :title="element.title"
          :button="element.button_name"
          :subtitle="element.subtitle"
          :pdf_url="element.pdf_url"
        >
        </SectionComponent>
    </div>
    </div>
    <SectionDuo :sections="sections" v-if="sections.length > 0"></SectionDuo>
    <SectionBusiness
      :business="business"
      v-if="sections.length > 0"
    ></SectionBusiness>
    <Footer class="pt-10 pb-10 footer-solid"></Footer>
  </div>
</template>

<script>
import NavBarComponent from "@/components/NavBarComponent/Index.vue";
import SectionComponent from "@/components/SectionComponent/Index.vue";
import SectionDuo from "@/components/SectionDuo/Index.vue";
import SectionBusiness from "@/components/SectionBusiness/Index.vue";
import Footer from "@/components/Footer/Index.vue";

export default {
  components: {
    NavBarComponent,
    SectionComponent,
    SectionDuo,
    SectionBusiness,
    Footer,
  },
  mounted() {
    this.getSections();
  },
  data: () => ({
    color: "#FFFFF",
    sections: [],
  }),
  computed: {
    introSection() {
      return this.sections.find(
        (section) => section.type_banner === "intro_section"
      );
    },
    sectionsFilter() {
      return this.sections.filter(
        (section) =>
          section.type_banner === "section_1" ||
          section.type_banner === "section_2" ||
          section.type_banner === "section_3"
      );
    },
    business() {
      return this.sections.find(
        (section) => section.type_banner === "section_business"
      );
    },
  },
  methods: {
    getSections() {
      const loading = this.$vs.loading({
        color: "#0276FF",
      });
      this.$store.dispatch("LandingModule/getSections").then((response) => {
        this.sections = response;
        loading.close();
      });
    },
    getPdf() {
      window.open(this.introSection.pdf_url, "_blank");
    },
  },
};
</script>

<style scoped>
.text-prueba {
  color: blue;
}

.bgblue-1 {
  /* background-image: url(../../../assets/images/bgblue1.svg); */
  height: 100vh;
  width: 100%;
}

@media only screen and (max-width: 639px) {
  .blocktoflex1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 86vh;
  }
  .pt-10 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
</style>
