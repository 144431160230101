<template>
  <div
    class="business bg-white h-1/3 flex justify-center py-24"
    v-if="business"
  >
    <div>
      <p class="btext-end text-center mt-8 mb-4">
        <span class="">{{ business.title }} </span>
        <!-- <span class="tblue-end-b">EN NOSOTROS</span> -->
      </p>
      <div class="flex flextogrid flex-wrap justify-center">
        <img
          class="mr-12 md:mr-16 aliados"
          src="../../assets/images/ferretotal.png"
          alt=""
        />
        <img
          class="mr-12 md:mr-16 aliados"
          src="../../assets/images/epa.png"
          alt=""
        />
        <img
          class="mr-12 md:mr-16 aliados"
          src="../../assets/images/preca.png"
          alt=""
        />
        <img
          class="mr-12 md:mr-16 aliados"
          src="../../assets/images/120negocios.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["business"],
};
</script>

<style>
.aliados {
  width: 110px;
  height: 100%;
  align-self: center;
}
/* .negocios{
  height: auto!important;
} */
@media only screen and (max-width: 639px) {
  .flextogrid {
    display: flex;
    justify-items: center;
    /* height: 15vh; */
  }
  .flextogrid .aliados {
    width: 100px;
    height: auto;
    margin: 5px 15px;
  }
  /* .pt-36 {
    padding: 40px 0px;
  } */
  .btext-end {
    font-size: 18px;
    margin-bottom: 40px;
  }
  .business {
    padding: 48px 0;
    height: auto;
  }
}

</style>
