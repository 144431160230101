<template>
  <div class="bg-rrss bg-cover flex flex-col justify-center items-center"
  :style="`background-image: url(${rrss.background_image})`">
    <p class="mb-2">
      <span class="title-section">{{ rrss.title }}</span>
      <!-- <span class="after-title">EN NUESTRAS REDES</span> -->
    </p>
    <p class="ig-user mb-4">{{ rrss.subtitle }}</p>
    <div class="pt-4 self-center">
      <a
        :href="rrss.instagram_url"
        target="_blank"
        class="button rounded-full py-2 px-6 bg-white box-border mr-4"
      >
        <img src="../../assets/images/instagram.svg" alt="" />
      </a>
      <a
        :href="rrss.facebook_url"
        target="_blank"
        class="button rounded-full py-2 px-6 bg-white box-border mr-4"
      >
        <img src="../../assets/images/facebook.svg" alt="" />
      </a>
      <a
        :href="rrss.whatsapp_url"
        target="_blank"
        class="button rounded-full py-2 px-6 bg-white box-border mr-4"
      >
        <img src="../../assets/images/message-circle.svg" alt="" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["rrss"],
};
</script>

<style>
.button {
  display: inline-block;
}
.bg-rrss {
  background-image: url(../../assets/images/bgblue3.svg);
  height: 40vh;
}

@media only screen and (max-width: 639px) {
  .bg-rrss {
    background-position: center;
  }
}
@media only screen and (max-width: 780px){
  .button{
    margin-right: 12px;
    padding: 8px 16px;
  }
}
@media only screen and (max-width: 680px){
  .button{
    padding: 8px 14px;
  }
}
</style>


